const EMOJI_BLOCKS = [
    0x00A0, 0x2030, 0x2040, 0x2120, 0x2130,
    0x2190, 0x21A0, 0x2310, 0x2320, 0x23C0, 
    0x23E0, 0x23F0, 0x24C0, 0x25A0, 0x25B0,
    0x25C0, 0x25F0, 0x2600, 0x2610, 0x2620,
    0x2630, 0x2640, 0x2650, 0x2660, 0x2670,
    0x2690, 0x26A0, 0x26B0, 0x26C0, 0x26D0, 
    0x26E0, 0x26F0, 0x2700, 0x2710, 0x2720,
    0x2730, 0x2740, 0x2750, 0x2760, 0x2790,
    0x27A0, 0x27B0, 0x2930, 0x2B00, 0x2B10,
    0x2B50, 0x3030, 0x3290, 0x1F000, 0x1F0C0,
    0x1F170, 0x1F180, 0x1F190, 0x1F200, 0x1F210,
    0x1F220, 0x1F230, 0x1F250, 0x1F300, 0x1F310,
    0x1F320, 0x1F330, 0x1F340, 0x1F350, 0x1F360,
    0x1F370, 0x1F380, 0x1F390, 0x1F3A0, 0x1F3B0,
    0x1F3C0, 0x1F3D0, 0x1F3E0, 0x1F3F0, 0x1F400,
    0x1F410, 0x1F420, 0x1F430, 0x1F440, 0x1F450,
    0x1F460, 0x1F470, 0x1F480, 0x1F490, 0x1F4A0, 
    0x1F4B0, 0x1F4C0, 0x1F4D0, 0x1F4E0, 0x1F4F0, 
    0x1F500, 0x1F510, 0x1F520, 0x1F530, 0x1F540, 
    0x1F550, 0x1F560, 0x1F570, 0x1F580, 0x1F590, 
    0x1F5A0, 0x1F5B0, 0x1F5C0, 0x1F5D0, 0x1F5E0, 
    0x1F5F0, 0x1F600, 0x1F610, 0x1F620, 0x1F630, 
    0x1F640, 0x1F680, 0x1F690, 0x1F6A0, 0x1F6B0, 
    0x1F6C0, 0x1F6D0, 0x1F6E0, 0x1F6F0, 0x1F7E0, 
    0x1F900, 0x1F910, 0x1F920, 0x1F930, 0x1F940, 
    0x1F950, 0x1F960, 0x1F970, 0x1F980, 0x1F990, 
    0x1F9A0, 0x1F9B0, 0x1F9C0, 0x1F9D0, 0x1F9E0, 
    0x1F9F0, 0x1FA70, 0x1FA80, 0x1FA90,   
]

export const getRandomEmoji = () => {
    const codePoint = EMOJI_BLOCKS[Math.floor(Math.random() * EMOJI_BLOCKS.length)] 
        + Math.floor(Math.random() * 16)
    return String.fromCodePoint(codePoint)
}

export const isExternalUrl = (url) => url.startsWith('https://') || url.startsWith('http://')

// `dateStr` format is [[dd/]mm/]yyyy
export const dateToTimestamp = (dateStr) => {
    const date = new Date()
    const parsedDate = dateStr.split('/')
    date.setFullYear(...parsedDate.map(parseFloat).reverse())
    return +date
}